<template>
    <div class="FolderAdd">
        <div class="bodyClass">
            <span>名称</span>
            <input v-model="folderName" placeholder="输入文件夹名" />
        </div>
        <div class="footClass" @click="submit()">完成</div>
    </div>

</template>
<script>
import titleMixin from '@/mixin/title'
import { mapGetters } from 'vuex'
export default {
    name: 'FolderAdd',
    // title: '新建文件夹',
    mixins: [titleMixin],
    data() {
        // let _this = this
        return {
            folderName: ''
        }
    },
    computed: {
        ...mapGetters('Mail', ['currentCtid'])
    },
    created() {

    },
    mounted() {
        this.pageInit()
    },
    methods: {
        // 完成提交
        submit() {
            let _this = this
            if (_this.folderName == '') {
                // this.$dialog.alert({ title: '提示', message: '请输入文件夹名称' })
                this.$toast.fail('请输入文件夹名称')
                return
            }
            if (_this.$route.params.boxId) {
                _this.ediFolder()
            } else {
                _this.addFolder()// 添加文件夹
            }

            // let folderName=this.folderName;
            //
        },
        // 添加文件夹
        addFolder() {
            let _this = this
            let data = {
                'boxName': _this.folderName,
                'bgColor': '0',
                'targetBoxId': this.$route.params.targetBoxId,
                'description': '',
                'directMerge': '',
                'boxRules': []
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            _this.axios.post(_this.Global.config.apiBaseURL + _this.Global.api.v2.mails_mailbox, data)
                .then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        _this.$toast.success('添加成功')
                        _this.folderName = ''
                        _this.$router.push('/mail/home')
                    } else {
                        _this.$toast.fail(res.data.msg)
                    }
                },
                    function (res) {
                        _this.$message.error(_this.$t(_this.Global.config.errorTitle))
                    })
        },
        // 修改文件夹
        ediFolder() {
            let _this = this
            let data = {
                'action': 'alter',
                'boxId': this.$route.params.boxId,
                'boxName': _this.folderName,
                'bgColor': '0',
                'description': '',
                'directMerge': '',
                'boxRulers': []
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            _this.axios.put(_this.Global.config.apiBaseURL + _this.Global.api.v2.mails_mailbox, data)
                .then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        _this.$toast.success('修改成功')
                        _this.folderName = ''
                        _this.$router.push('/mail/home')
                    } else {
                        _this.$toast.fail(res.data.msg)
                    }
                },
                    function (res) {
                        _this.$message.error(_this.$t(_this.Global.config.errorTitle))
                    })
        },
        setMentu() {
            this.Global.utils.rightMenu.clearMenu()
            if (this.$route.params.type == 'addSub') {
                this.setHeaderTitle('新建子文件夹')
            } else if (this.$route.params.type == 'addPeer') {
                this.setHeaderTitle('新建同级文件夹')
            } else {
                this.setHeaderTitle('修改文件夹名称')
            }
        },
        pageInit() {
            this.setMentu()
            this.folderName = this.$route.params.boxName == undefined ? '' : this.$route.params.boxName
        }
    },
    watch: {
        $route(to, old) {
            if (this.$route.path == '/mail/folderAdd') {
                this.pageInit()
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
